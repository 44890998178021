<template>
	<div class="tradeMain" style="background-color: #fff;">
		<div class="flexPage">
			<div class="flexHead">
				<navbar :params="pageInfo" />

				<div class="mt15">
					<img src="../../assets/img/btc.png" alt="" style="width: 48px;height: 48px;margin-right: 16px;">
					<div class="current-price">
						<div class="symobl">{{ $route.params.symbol }}</div>
						<div><b :class="[currentCoin.chg > 0 ? 'price-rise' : 'price-decline']">{{
							currentCoin.close
						}}</b></div>
					</div>
				</div>

				<div class="align-center mt30">
					<div class="justify-content flex-one cny-price">
						{{ $t('other.translate134') }}
						<div :class="[currentCoin.chg > 0 ? 'color_16C784' : 'chg']">
							<img src="../../assets/img/up.png" v-if="currentCoin.chg > 0" alt=""
								style="width: 16px;height: 16px;margin-right: 4px;">
							<img src="../../assets/img/down.png" v-else alt=""
								style="width: 16px;height: 16px;margin-right: 4px;">
							{{ currentCoin.chg }}%
						</div>
					</div>
					<div class="partition"></div>
					<div class="justify-content flex-one cny-price">
						{{ $t('other.translate135') }}
						<p class="high" style="margin: 0">${{ currentCoin.high }}</p>
					</div>
					<div class="partition"></div>
					<div class="justify-content flex-one cny-price">
						{{ $t('other.translate136') }}
						<p class="low" style="margin: 0">${{ currentCoin.low }}</p>
					</div>
				</div>
			</div>
			<div class="flexContent">
				<div class="tradingViewBox" v-show="tabActive == 1">
					<div class="tradingViewHead">
						<template v-for="(item, index) of resolutions">
							<span v-if="index <= 4" :class="item.ratio == interval ? 'active' : ''" :key="index"
								@click="changeTv(item.ratio)">
								{{ item.name }}
							</span>
						</template>
						<span @click="popMenu.show = true"
							:class="{ active: (interval == '240' || interval == '1D' || interval == '1W') }">{{
								$t('trade.sec.more') }}</span>
					</div>
					<div class="tradingView" id="tvbox1">
					</div>
				</div>
			</div>
			<ul class="tmbar">
				<li class="tmBtn" style="background: #34A853;border-radius: 4px;">
					<router-link :to="{ name: 'trade' }">{{ $t('other.translate9') }}</router-link>
				</li>
				<li class="tmBtn" style="background: #FA4940;border-radius: 4px;">
					<router-link :to="{ name: 'trade' }">{{ $t('other.translate10') }}</router-link>
				</li>
			</ul>
		</div>
		<van-action-sheet class="dark" v-model="popMenu.show" :actions="popMenu.actions" @select="onMenuSelect" />

	</div>
</template>
<script>
import navbar from '@/views/modules/navbar.vue';
import Datafeeds from '../../../public/charting_library/datafeed/bitrade';


let Stomp = require('stompjs');
let SockJS = require('sockjs-client');

export default {
	data() {
		return {
			pageInfo: {
				customTitle: this.$route.params.symbol,
				border: false,
				navbarStyle: 'normal',
				backname: ''
			},

			popMenu: {
				show: false,
				actions: [
					{
						name: '4' + this.$t('trade.canvas.h2'),
						value: 14400,
						ratio: '240'
					},
					{
						name: '1' + this.$t('trade.canvas.day'),
						value: 86400,
						ratio: '1D'
					},
					{
						name: '1' + this.$t('trade.canvas.week'),
						value: 604800,
						ratio: '1W'
					},
				],
			},

			datafeed: null,
			tabActive: 1,
			den: 1,
			symbol: '', // 订阅的商品
			interval: '5', // 默认订阅的 分辨率
			resolutions: [
				{
					name: this.$t('trade.canvas.times'),
					value: 60,
					ratio: '1'
				},
				{
					name: '5' + this.$t('trade.canvas.min'),
					value: 300,
					ratio: '5'
				},
				{
					name: '15' + this.$t('trade.canvas.min'),
					value: 900,
					ratio: '15'
				},
				{
					name: '30' + this.$t('trade.canvas.min'),
					value: 1800,
					ratio: '30'
				},
				{
					name: '1' + this.$t('trade.canvas.h'),
					value: 3600,
					ratio: '60'
				},
				{
					name: '4' + this.$t('trade.canvas.h2'),
					value: 14400,
					ratio: '240'
				},
				{
					name: '1' + this.$t('trade.canvas.day'),
					value: 86400,
					ratio: '1D'
				},
				{
					name: '1' + this.$t('trade.canvas.week'),
					value: 604800,
					ratio: '1W'
				},
			],
			coins: {
				_map: [],
				USDT: [],
				BTC: [],
				ETH: [],
				USDT2: [],
				BTC2: [],
				ETH2: [],
				favor: [],
				columns: [
					{
						title: '币种',
						key: 'coin',
						sortable: false,
						width: 85,
						// className: "coin-menu-symbol",
						render: (h, params) => {
							if (params.row.coin == 'BZB') {
								return h('div', [
									h('Icon', {
										props: {
											// color:"red",
											type: params.row.isFavor ? 'ios-star' : 'ios-star-outline'
										},
										nativeOn: {
											click: () => {
												event.stopPropagation(); //阻止事件冒泡
												if (this.isLogin) {
													if (event.currentTarget.className == 'ivu-icon ivu-icon-ios-star') {
														// this.cancelCollect(params.index, params.row);
														event.currentTarget.className == 'ivu-icon ivu-icon-ios-star-outline';
													} else {
														this.collect(params.index, params.row);
														event.currentTarget.className = 'ivu-icon ivu-icon-ios-star';
													}
												} else {
													this.$total('请先登录');
												}
											}
										}
									}),
									h('span', params.row.coin),
									h(
										'span',
										{
											style: {
												fontSize: '8px',
												padding: '2px 5px 1px 5px',
												color: '#FFF',
												marginLeft: '5px',
												background: '#F30',
												borderRadius: '4px'
											}
										},
										'HOT'
									)
								]);
							}
							return h('div', [
								h('Icon', {
									props: {
										// color:"red",
										type: params.row.isFavor ? 'ios-star' : 'ios-star-outline'
									},
									nativeOn: {
										click: () => {
											event.stopPropagation(); //阻止事件冒泡
											if (this.isLogin) {
												if (event.currentTarget.className == 'ivu-icon ivu-icon-ios-star') {
													// this.cancelCollect(params.index, params.row);
													event.currentTarget.className == 'ivu-icon ivu-icon-ios-star-outline';
												} else {
													this.collect(params.index, params.row);
													event.currentTarget.className = 'ivu-icon ivu-icon-ios-star';
												}
											} else {
												this.$total('请先登录');
											}
										}
									}
								}),
								h('span', params.row.coin)
							]);
						}
					},
					{
						title: this.$t('exchange.lastprice'),
						key: 'close',
						sortable: true,
						width: 100,
						sortMethod: function (a, b, type) {
							let a1 = parseFloat(a);
							let b1 = parseFloat(b);
							if (type == 'asc') {
								return a1 - b1;
							} else {
								return b1 - a1;
							}
						}
					},
					{
						title: this.$t('exchange.daychange'),
						key: 'rose',
						sortable: true,
						sortMethod: function (a, b, type) {
							let a1 = a.replace(/[^\d|.|-]/g, '') - 0;
							let b1 = b.replace(/[^\d|.|-]/g, '') - 0;
							if (type == 'asc') {
								return a1 - b1;
							} else {
								return b1 - a1;
							}
						},
						render: (h, params) => {
							const row = params.row;
							const color = row.rose.substring(0, 1) === '+' ? 'rgb(29, 179, 171)' : 'rgb(215, 78, 90)';
							return h(
								'span',
								{
									style: {
										color
									}
								},
								row.rose
							);
						}
					}
				]
			},
			baseCoinScale: 2,
			currentCoin: {
				base: '',
				coin: '',
				symbol: '',
				levers: [10, 20, 30]
			},
		}
	},
	components: { navbar },
	watch: {},
	computed: {},
	mounted() {
		this.initTv();
	},
	methods: {
		getListenerGUID() { // 监听的GUID
			return this.symbol + '_' + this.interval;
		},
		changeTv(interval) {
			// 取消原有订阅
			// window.udf.unsubscribeBars(this.getListenerGUID());
			console.log("change =============   ", interval);
			this.interval = interval;
			const thats = window.tvWidget;
			if (interval === '1') {
				thats.chart().setChartType(3);
			} else {
				thats.chart().setChartType(1);
			}
			// 更新数据
			thats.chart().setResolution(interval, function onReadyCallback() { });
		},
		onMenuSelect(item) {
			this.popMenu.show = false;
			this.changeTv(item.ratio)
		},
		initTv() {
			var params = this.$route.params.symbol;
			let coin = params.toUpperCase().split('/')[0];
			let base = params.toUpperCase().split('/')[1];
			this.currentCoin.symbol = coin + '/' + base;
			this.currentCoin.coin = coin;
			this.currentCoin.base = base;
			let scripts = [
				"charting_library/charting_library.min.js?v=" + this.$mts.v,
				"charting_library/datafeed/udf/datafeed.js?v=" + this.$mts.v
			];
			let _self = this;
			this.$mts.seriesLoadScripts(scripts, () => {
				this.getSymbolScale();
				this.getSymbol();
			});
		},
		//获取精度
		getSymbolScale() {
			this.$http.post(this.host + '/market/spot-market/symbol-info', { symbol: this.currentCoin.symbol }).then((response) => {
				let resp = response.body;
				if (resp != null) {
					this.currentCoin.coinScale = resp.coinScale;
					this.currentCoin.baseCoinScale = resp.baseCoinScale;
					this.baseCoinScale = resp.baseCoinScale;
				}
			});
		},
		getSymbol() {
			this.$http.post(this.host + '/market/spot-market/symbol-thumb', {}).then((response) => {
				var resp = response.body;
				//先清空已有数据
				for (var i = 0; i < resp.length; i++) {
					var coin = resp[i];
					coin.base = resp[i].symbol.split('/')[1];
					this.coins[coin.base] = [];
					this.coins[coin.base + '2'] = [];
					this.coins._map = [];
					this.coins.favor = [];
				}
				for (var i = 0; i < resp.length; i++) {
					var coin = resp[i];
					coin.price = resp[i].close = resp[i].close.toFixed(this.baseCoinScale);
					coin.rose = resp[i].chg > 0 ? '+' + (resp[i].chg * 100).toFixed(2) + '%' : (resp[i].chg * 100).toFixed(2) + '%';
					coin.coin = resp[i].symbol.split('/')[0];
					coin.base = resp[i].symbol.split('/')[1];
					coin.href = (coin.coin + '_' + coin.base).toLowerCase();
					coin.isFavor = false;
					this.coins._map[coin.symbol] = coin;
					// console.log('coin', coin);
					if (coin.zone == 0 && coin.exchangeable == 1) {
						this.coins[coin.base].push(coin);
					} else {
						this.coins[coin.base + '2'].push(coin);
					}
					if (coin.symbol == this.currentCoin.symbol) {
						this.currentCoin = coin;
					}
				}
				require(['../../assets/js/exchange.js'], function (e) {
					e.clickScTab();
				});
				this.startWebsock();
			});
		},
		initTradeView() {
			let that = this;
			window.tvWidget = new TradingView.widget({
				width: "100%",
				height: "100%",
				fullscreen: false,
				autosize: true,
				timezone: "Asia/Shanghai",
				symbol: this.coinPair,
				interval: this.interval, // 分表率
				container_id: "tvbox1",
				datafeed: that.datafeed,
				library_path: "charting_library/",
				locale: this.$mts.getLangSm() || "en",
				toolbar_bg: "#000",
				drawings_access: {
					type: 'black',
					tools: [{
						name: "Regression Trend",
						grayed: true
					}]
				},
				disabled_features: [
					'header_symbol_search',
					"use_localstorage_for_settings",
					"left_toolbar",
					'legend_context_menu',
					"border_around_the_chart",
					"timeframes_toolbar",
					"volume_force_overlay",
					"pane_context_menu",
					"header_symbol_search",
					"symbol_search_hot_key",
					"header_undo_redo",
					"header_compare",
					"header_chart_type",
					"header_screenshot",
					"header_resolutions",
					"header_widget",
					"control_bar",
					"adaptive_logo",
					"display_market_status",
					"pane_controls"
				],
				enabled_features: [],
				charts_storage_api_version: "1.1",
				overrides: { //画布
					"paneProperties.background": "#fff", //整体画布背景
					"paneProperties.vertGridProperties.color": "#555", //画布竖线颜色
					"paneProperties.horzGridProperties.color": "#555", //画布横线颜色
					"symbolWatermarkProperties.transparency": 90,
					"scalesProperties.textColor": "#ccc", //画布文字颜色
					"mainSeriesProperties.candleStyle.upColor": "#03be87", //涨
					"mainSeriesProperties.candleStyle.downColor": "#e66b41", //跌

					"mainSeriesProperties.candleStyle.borderColor": "#333",
					"mainSeriesProperties.candleStyle.borderUpColor": "#03be87",
					"mainSeriesProperties.candleStyle.borderDownColor": "#e66b41",

					"linetoolicon.color": 'rgba( 0, 0, 0, 1)',

					'paneProperties.vertGridProperties.style': 0,
					'paneProperties.horzGridProperties.color': 'rgba(0,0,0,0)',
					'paneProperties.vertGridProperties.color': 'rgba(0,0,0,0)',

					"paneProperties.legendProperties.showLegend": false
				},
				studies_overrides: { //画布底部绘图区域
					"volume.volume.color.0": "#e66b41",
					"volume.volume.color.1": "#03be87",
					"volume.volume.transparency": 70,
					"volume.volume ma.color": "#FF0000",
					"volume.volume ma.transparency": 30,
					"volume.volume ma.linewidth": 5,
					"volume.show ma": true,
					"bollinger bands.median.color": "#33FF88",
					"bollinger bands.upper.linewidth": 7
				}
			});
			var thats = window.tvWidget;
			window.tvWidget.onChartReady(function () {
				let MaLine5 = thats.chart().createStudy('Moving Average', false, false, [5], null, {
					'Plot.color': '#000000'
				});
				let MaLine15 = thats.chart().createStudy('Moving Average', false, false, [15], null, {
					'Plot.color': '#03be87'
				});
				let MaLine30 = thats.chart().createStudy('Moving Average', false, false, [30], null, {
					'Plot.color': '#e66b41'
				});
				let MaLine60 = thats.chart().createStudy('Moving Average', false, false, [60], null, {
					'Plot.color': '#ff0000'
				});

				thats.chart().setEntityVisibility(MaLine5, false);
				thats.chart().setEntityVisibility(MaLine15, false);
				thats.chart().setEntityVisibility(MaLine30, false);
				thats.chart().setEntityVisibility(MaLine60, false);
			});
		},
		getCoin(symbol) {
			return this.coins._map[symbol];
		},
		startWebsock() {
			if (this.stompClient) {
				this.stompClient.ws.close();
			}
			let stompClient = null;
			let that = this;
			let socket = new SockJS(that.host + '/market/market-ws');
			stompClient = Stomp.over(socket);
			this.stompClient = stompClient;
			stompClient.debug = false;
			stompClient.connect({}, function (frame) {
				that.datafeed = new Datafeeds.WebsockFeed(that.host + '/market/spot-market', that.currentCoin, stompClient, that.baseCoinScale);
				that.initTradeView();
				//订阅价格变化消息
				stompClient.subscribe('/topic/spot-market/thumb', function (msg) {
					var resp = JSON.parse(msg.body);
					var coin = that.getCoin(resp.symbol);
					if (coin != null) {
						coin.price = resp.close;
						coin.rose = resp.chg > 0 ? '+' + (resp.chg * 100).toFixed(2) + '%' : (resp.chg * 100).toFixed(2) + '%';
						coin.close = resp.close;
						coin.high = resp.high;
						coin.low = resp.low;
						coin.turnover = parseInt(resp.volume);
						coin.volume = resp.volume;
						coin.usdRate = resp.usdRate;
					}
				});
				//订阅实时成交信息
				stompClient.subscribe('/topic/spot-market/trade/' + that.currentCoin.symbol, function (msg) {
					var resp = JSON.parse(msg.body);
					if (resp.length > 0) {
						for (var i = 0; i < resp.length; i++) {
							that.trade.rows.unshift(resp[i]);
						}
					}
					if (that.trade.rows.length > 16) {
						that.trade.rows = that.trade.rows.slice(0, 16);
					}
				});
				if (that.isLogin) {
					//订阅委托取消信息
					stompClient.subscribe('/topic/spot-market/order-canceled/' + that.currentCoin.symbol + '/' + that.member.id, function (msg) {
						var resp = JSON.parse(msg.body);
						that.refreshAccount();
					});
					//订阅委托交易完成
					stompClient.subscribe('/topic/spot-market/order-completed/' + that.currentCoin.symbol + '/' + that.member.id, function (msg) {
						var resp = JSON.parse(msg.body);
						that.refreshAccount();
					});
					//订阅委托部分交易
					stompClient.subscribe('/topic/spot-market/order-trade/' + that.currentCoin.symbol + '/' + that.member.id, function (msg) {
						var resp = JSON.parse(msg.body);
						that.refreshAccount();
					});
				}

				//订阅盘口消息
				stompClient.subscribe('/topic/spot-market/trade-plate/' + that.currentCoin.symbol, function (msg) {
					var resp = JSON.parse(msg.body);
					{
						let sellTradePlate = resp.sellTradePlate;
						let asks = sellTradePlate.items;
						that.plate.askRows = [];
						let totle = 0;
						for (let i = that.plate.maxPostion - 1; i >= 0; i--) {
							let ask = {};
							if (i < asks.length) {
								ask = asks[i];
							} else {
								ask['price'] = 0;
								ask['amount'] = 0;
							}
							ask.direction = 'SELL';
							ask.position = i + 1;
							that.plate.askRows.push(ask);
						}
						for (let i = that.plate.askRows.length - 1; i >= 0; i--) {
							if (i == that.plate.askRows.length - 1 || that.plate.askRows[i].price == 0) {
								that.plate.askRows[i].totalAmount = that.plate.askRows[i].amount;
							} else {
								that.plate.askRows[i].totalAmount = that.plate.askRows[i + 1].totalAmount + that.plate.askRows[i].amount;
							}
							totle += that.plate.askRows[i].amount;
						}
						that.plate.askTotle = totle;
					}
					{
						let buyTradePlate = resp.buyTradePlate;
						let bids = buyTradePlate.items;
						that.plate.bidRows = [];
						let totle = 0;
						for (let i = 0; i < that.plate.maxPostion; i++) {
							let bid = {};
							if (i < bids.length) {
								bid = bids[i];
							} else {
								bid['price'] = 0;
								bid['amount'] = 0;
							}
							bid.direction = 'BUY';
							bid.position = i + 1;
							that.plate.bidRows.push(bid);
						}
						for (let i = 0; i < that.plate.bidRows.length; i++) {
							if (i == 0 || that.plate.bidRows[i].amount == 0) {
								that.plate.bidRows[i].totalAmount = that.plate.bidRows[i].amount;
							} else {
								that.plate.bidRows[i].totalAmount = that.plate.bidRows[i - 1].totalAmount + that.plate.bidRows[i].amount;
							}
							totle += that.plate.bidRows[i].amount;
						}
						that.plate.bidTotle = totle;
					}
				});
			});
		},
	}
}
</script>
<style>
.symobl {
	font-family: Inter;
	font-size: 14px;
	font-weight: 600;
	line-height: 19.6px;
}

.mt15 {
	margin-top: 3.8168vw;
	display: flex;
	align-items: center;
	margin-left: 16px;
}

.mt30 {
	margin-top: 16px;
}

.align-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

/* .justify-content {
	display: flex;
	align-items: center;
}

.flex-one {
	flex: 1;
} */

.partition {
	width: 2px;
	height: 7.6336vw;
	background-color: #D9D9D9;
	margin: 0 24px;
}

.current-price {
	font-size: 12px;
	color: #0D1421;
}

.current-price .price-rise {
	font-size: 24px;
	color: #000;
}

.chg {
	font-size: 12px;
	color: #000;
	display: flex;
	align-items: center;
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 19.6px;
}

.color_16C784 {
	color: #000;
	display: flex;
	align-items: center;
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 19.6px;

}

.cny-price {
	color: #616E85;
	width: 21.6285vw;
}

.high,
.low {
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	color: #000
}

.current-price .price-decline {
	font-size: 24px;
	color: #000;
}


.ml15 {
	margin-left: 3.8168vw;
}
</style>
